import { PageNotFoundRoute } from './pages/PageNotFound';
import { PolicyRoute } from './pages/Policy';
import { RegisterRoute } from './pages/Register';
import { SelectNetwork } from './pages/SelectNetwork';
import { TermsRoute } from './pages/Terms';
import { WelcomeRoute } from './pages/Welcome';

export const routes = [
    {
        path: '/terms-and-conditions',
        exact: true,
        component: TermsRoute,
    },
    {
        path: '/privacy-policy',
        exact: true,
        component: PolicyRoute,
    },
    {
        path: '/welcome',
        exact: true,
        component: WelcomeRoute,
    },

    {
        path: '/select-network',
        name: 'Select Network',
        exact: true,
        component: SelectNetwork,
    },
    {
        path: '/:affiliate?',
        name: 'Page',
        exact: true,
        component: RegisterRoute,
    },
    {
        path: '*',
        component: PageNotFoundRoute,
    },
];
