import { Country } from '../enums/country';
import { Network } from '../enums/network';
import { NetworkProvider } from '../types/networkProvider';

type InternationalMobileSubscriberIdentity = {
    MCC: string;
    MNC: string;
    network: Network;
    countryCode: Country;
};

const networks: Array<InternationalMobileSubscriberIdentity> = [
    {
        MCC: '620',
        MNC: '01',
        network: Network.MTN,
        countryCode: Country.GHANA,
    },
    {
        MCC: '620',
        MNC: '02',
        network: Network.VODAFONE,
        countryCode: Country.GHANA,
    },
    {
        MCC: '620',
        MNC: '03',
        network: Network.AIRTEL,
        countryCode: Country.GHANA,
    },
    {
        MCC: '620',
        MNC: '06',
        network: Network.AIRTEL,
        countryCode: Country.GHANA,
    },
    {
        MCC: '621',
        MNC: '30',
        network: Network.MTNN,
        countryCode: Country.NIGERIA,
    },
    {
        MCC: '621',
        MNC: '20',
        network: Network.AIRTEL_NG,
        countryCode: Country.NIGERIA,
    },
    {
        MCC: '621',
        MNC: '50',
        network: Network.GLO,
        countryCode: Country.NIGERIA,
    },
    {
        MCC: '645',
        MNC: '02',
        network: Network.MTN,
        countryCode: Country.ZAMBIA,
    },
];

const getNetworkProvider = (imsi: string): NetworkProvider => {
    const mcc = imsi.substring(0, 3);
    const mnc = imsi.substring(3, 5);

    const provider = networks.find(network => {
        return network.MCC === mcc && network.MNC === mnc;
    });

    if (provider) {
        return { network: provider.network, countryCode: provider.countryCode };
    }

    return { network: Network.OTHER, countryCode: Country.UNDEFINED };
};

export { getNetworkProvider };
