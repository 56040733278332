import { RegisterProps } from '../pages/Register/types/registerProps';
import { UserCredentials } from '../../shared/types/userCredentials';

const getUserCredentialsFromProps = ({
    phoneNumber = '',
    network,
}: Partial<RegisterProps>): UserCredentials => ({
    fullName: undefined,
    email: undefined,
    phoneNumber,
    network,
    password: '',
    confirmPassword: '',
});

export { getUserCredentialsFromProps };
