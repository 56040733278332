import { Checkout } from '../types/checkout';

const getCheckout = (search: URLSearchParams): Checkout | undefined => {
    const action = search.get('action') || '';
    const plan = search.get('plan') || '';
    const clickId = search.get('click_id') || search.get('clickId') || '';
    const variant = search.get('variant') || '';
    const promotional = search.get('promotional') || '';

    if (!clickId && !plan) return undefined;

    // only return keys that have values, remove keys with empty strings as values
    return {
        ...(action && { action }),
        ...(plan && { plan }),
        ...(clickId && { clickId }),
        ...(variant && { variant }),
        ...(promotional && { promotional }),
    };
};

export { getCheckout };
