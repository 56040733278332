import { ThemeProvider, Typography } from '@mui/material';
import { Ctx } from '@wi-flix/after';
import { AsyncRouteComponentType } from '@wi-flix/after/src/types';
import { Component, ErrorInfo, ReactNode } from 'react';

import { theme } from '../../../shared/theme';
import { Error500 } from '../ErrorBoundary/Error500';
import { CssReset } from './CssReset';

type RootState = {
    error?: Error;
};

function withRoot<T>(BaseComponent: AsyncRouteComponentType<T>): AsyncRouteComponentType<T> {
    class Root extends Component<T, RootState> {
        constructor(props: T) {
            super(props);
            this.state = { error: undefined };
        }

        componentDidMount(): void {
            // Remove the server-side injected CSS.
            const serverStyles = document.querySelector('#server-styles');
            if (serverStyles && serverStyles.parentNode) {
                serverStyles.parentNode.removeChild(serverStyles);
            }
        }

        componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
            // eslint-disable-next-line no-console
            console.log(errorInfo);
            this.setState({ error });
        }

        static getChunkName(): string | undefined {
            return BaseComponent.getChunkName?.() || 'Root';
        }

        static getInitialProps(props: Ctx<any>): any {
            return BaseComponent.getInitialProps?.(props) || {};
        }

        render(): ReactNode {
            const { error } = this.state;

            return (
                <ThemeProvider theme={theme}>
                    <CssReset />
                    {(() => {
                        if (error) {
                            // TODO: do a proper error reporting page
                            return (
                                <Error500>
                                    <Typography variant="subtitle1">{error.message}</Typography>
                                </Error500>
                            );
                        }

                        // eslint-disable-next-line react/jsx-props-no-spreading
                        return <BaseComponent {...this.props} />;
                    })()}
                </ThemeProvider>
            );
        }
    }

    return Root;
}

export { withRoot };
