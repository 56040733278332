import { Ctx, RedirectWithStatuCode } from '@wi-flix/after/src/types';
import axios from 'axios';

import { Network } from '../../../shared/enums/network';
import { authByHeaderEnrichment } from '../../../shared/helpers/authByHeaderEnrichment';
import { getAuthCookies } from '../../../shared/helpers/cookies';
import { getCheckout } from '../../../shared/helpers/getCheckout';
import { getHeaderEnrichedAuth } from '../../../shared/helpers/getHeaderEnrichedAuth';
import { isAuthenticated } from '../../../shared/helpers/isAuthenticated';
import { getTarget } from '../../helpers/getTarget';
import { MatchParams } from './types/matchParams';
import { RegisterProps } from './types/registerProps';

const getInitialProps = async (
    props: Ctx<MatchParams>,
): Promise<RegisterProps & RedirectWithStatuCode> => {
    const search = new URLSearchParams(
        props.req?.originalUrl.split('?')[1] || props.location?.search,
    );

    const checkout = getCheckout(search);
    const target = getTarget(checkout);

    if (isAuthenticated(props.req)) {
        return {
            redirectTo: target,
            statusCode: 302,
        };
    }

    const headerEnrichedAuth = getHeaderEnrichedAuth(search);
    if (headerEnrichedAuth && headerEnrichedAuth.network !== Network.OTHER) {
        try {
            // check if the number is in GH blacklisted list
            if (
                headerEnrichedAuth.phoneNumber.startsWith('233') ||
                headerEnrichedAuth.phoneNumber.startsWith('+233') ||
                headerEnrichedAuth.network === Network.MTN
            ) {
                const { data } = await axios.post(
                    'https://us-central1-wi-flix.cloudfunctions.net/check-blacklisted',
                    {
                        number: headerEnrichedAuth.phoneNumber,
                    },
                );
                if (data.blacklisted) return { ...headerEnrichedAuth };
            }
            const authSession = await authByHeaderEnrichment(headerEnrichedAuth);
            const authCookies = getAuthCookies(authSession, props.req?.header('referer') || '');

            authCookies.forEach(cookie => {
                props.res?.cookie(cookie.name, cookie.value, cookie.options);
            });

            return {
                redirectTo: target,
                statusCode: 302,
            };
        } catch (e) {
            return {
                phoneNumber: headerEnrichedAuth.phoneNumber,
                network: headerEnrichedAuth.network,
            };
        }
    }

    return {
        network: (search.get('nw') as Network) || '',
        ...(headerEnrichedAuth || {}),
    };
};

export { getInitialProps };
